<template>
  <div>
    <base-template
      :sub-text="$t('login.nextStep')"
      show-btn
      :submit-flag="dataForm.code.length === 4"
      @submit="validateCode(dataForm.code)"
    >
      <LoginTop class="login-top" />
      <div class="send-code-box">
        <div class="item-title">{{ $t('login.inputValidCode') }}</div>
        <code-input v-model="dataForm.code">
          <template #button>
            <send-code-button ref="sendCodeButton" v-model="dataForm.code" :module-type="type" :mobile="dataForm.phone" />
          </template>
        </code-input>
      </div>
      <p class="send-voice"><span @click="getVoiceCode()">{{ $t('login.sendVoice') }}</span></p>
      <privacidad v-if="privacidad" ref="privacidad" class="privacidad" @changeHandle="changeHandle" />
    </base-template>
  </div>
</template>

<script>
import CodeInput from '@/components/CodeInput'
import LoginTop from '@/views/login/components/LoginTop'
import Privacidad from './components/Privacidad'
import SendCodeButton from '@/components/SendCodeButton'
import { userLogin } from '@/api/axios/index'
export default {
  name:'Register',
  components: { CodeInput, LoginTop, Privacidad, SendCodeButton },
  data() {
    return {
      privacidad: false,
      dataForm: {
        phone: this.$route.query.mobile,
        code: '',
        code_type: '1'
      },
      type: 'register'
    }
  },
  created() {
    this.type = this.$route.query.forgetPass === 'forgetPass' ? 'change_pwd' : 'register'
    this.$nextTick(() => {
      this.$refs.sendCodeButton.sendSms(1)
    })
  },
  
  methods: {

    

    async validateCode(val) {
      // const valid = await this.$refs.sendCodeButton.checkNewsCode(val)
      // if (valid) {}
      const Umusango_Ibumba = this.$refs.sendCodeButton.codeType + '' === '1' ? '2' : '3'
      try {
        const { data } = await userLogin({ mobile_phone_number: this.dataForm.phone, Umusango_Ibumba: Umusango_Ibumba, Infungulo_yankama: this.dataForm.code })
        console.log(data)
        this.$router.push({
          path: '/setPassWord',
          query: {
            mobile: this.dataForm.mobile,
            forgetPass: this.$route.query.forgetPass,
            customer_id: data.Imimonekele_kulanga,
            ...data
          }
        })
      } catch (err) {
        console.log(err)
      }
    },

    // 获取语音验证
    getVoiceCode() {
      this.$refs.sendCodeButton.sendSms(2)
    },

    changeHandle(val) {

    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  padding-left: 64px;
  padding-right: 64px;
}
:deep(.main-content){
  background: #fff;
  .child-title{
    display: none;
  }
}

.code-send-success{
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #002424;
  line-height: 32px;
  text-align: center;
  padding-top: 60px;
}
.send-voice{
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #005FE9;
  line-height: 30px;
  margin-top: 32px;
  text-align: right;
  padding-bottom: 128px;
}

.send-code-box{
  margin-top: 128px;
}

.privacidad{
  position: fixed;
  bottom: 70px;
  left: 30px;
}

.item-title{
  font-size: 32px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #1A1A1A;
  line-height: 48px;
  padding-bottom: 32px;
}
.use-phone-box{
  margin-bottom: 50px;
  .item-mobile{
    height: 128px;
    line-height: 128px;
    background: #F7F7F7FF;
    border-radius: 32px;
    font-size: 28px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #000000;
    padding-left: 48px;
  }
}
:deep(.login-bg){
  margin-bottom:98px;
}

:deep(.code-input){
  height: 128px;
  line-height: 128px;
  background: #F7F7F7FF;
  border-radius: 32px;
  opacity: 1;
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #1F1F39;
  text-indent: 48px;
  border: none;
}
:deep(.code-input-box){
  display: none;
}
:deep(.send-code-btn){
  height: 128px;
  line-height: 128px;
  padding-right: 40px;
}
.login-top{
  margin-top: 42px;
}
</style>
